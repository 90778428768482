import { useEffect, useState } from 'react'
import styled from 'styled-components'
import PageTitle from '../../../components/PageTitle'
import { useTranslation } from 'react-i18next'

function Product() {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [data, setData] = useState([])
  useEffect(() => {
    fetch('/data/Business/Product/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, [])

  const subtext = t('product_title')

  return (
    <>
      <PageTitle title="Product" subtext={subtext} />
      <ProductWrapper>
        <Content>
          {data.map((data) => (
            <PrdList key={data.id}>
              <PrdImg>
                <img
                  src={language === 'ko' ? data.image : data.image_en}
                  alt={data.title}
                />
              </PrdImg>
              <PrdInfo>
                <Number>{data.number}</Number>

                <Title>{data.title}</Title>

                <SubTitle>{t(data.subtitle)}</SubTitle>
                <img className="moImg" src={data.image} alt={data.title} />

                <Description>{t(data.description_pc)}</Description>

                <BtnBox>
                  {data.appstore_link && (
                    <AppStoreBtn
                      onClick={() =>
                        window.open(`${data.appstore_link}`, '_blank')
                      }
                    />
                  )}
                  {data.googleplay_link && (
                    <GooglePlayBtn
                      onClick={() =>
                        window.open(`${data.googleplay_link}`, '_blank')
                      }
                    />
                  )}
                  {data.link && (
                    <LinkButton
                      onClick={() => window.open(`${data.link}`, '_blank')}
                    >
                      <img
                        src={data.icon}
                        alt={`go to ${data.title} homepage`}
                      />
                      <span>{t('homepage')}</span>
                    </LinkButton>
                  )}
                </BtnBox>
              </PrdInfo>
            </PrdList>
          ))}
        </Content>
      </ProductWrapper>
    </>
  )
}

const ProductWrapper = styled.section`
  position: relative;
  width: 100%;

  background-color: ${(props) => props.theme.color.WPrimary};
  @media all and (max-width: 768px) {
    min-width: 100%;

    padding: 5rem 2rem;
  }
`
const Content = styled.article`
  margin: 180px 0 200px 0;
  div:nth-child(even) {
    flex-direction: row-reverse;
  }
  @media all and (max-width: 768px) {
    margin-top: -6rem;
    flex-direction: column;
  }
`
const PrdList = styled.div`
  display: flex;
  width: 100%;
  gap: 100px;
  justify-content: space-between;
  margin-top: 130px;

  @media all and (max-width: 768px) {
    flex-direction: column-reverse;
    margin-top: 5rem;
    height: unset;
  }
`
const PrdImg = styled.div`
  width: 100%;
  img {
    width: 100%;
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`
const PrdInfo = styled.div`
  width: 100%;
  .moImg {
    display: none;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    padding: 0;
    .moImg {
      display: block;
      width: 100%;
    }
  }
`
const Number = styled.p`
  margin-bottom: 30px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 72px;
  line-height: 72px;
  color: ${(props) => props.theme.color.GSecondary};
  opacity: 0.5;
  @media all and (max-width: 768px) {
    display: none;
  }
`
const Title = styled.p`
  margin-bottom: 30px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  color: ${(props) => props.theme.color.GPrimary};
  @media all and (max-width: 768px) {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    color: #77cae4;
    margin-bottom: 0;
  }
`
const SubTitle = styled.p`
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: ${(props) => props.theme.color.TPrimary};

  @media all and (max-width: 768px) {
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    color: #666666;
  }
`
const Description = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: ${(props) => props.theme.color.TPrimary};
  white-space: pre-line;
  @media all and (max-width: 768px) {
    white-space: normal;

    font-size: 0.9rem;
    font-weight: 400;
    font-family: 'Noto Sans KR';
    color: #666666;
    line-height: 2.1rem;
  }
`

const BtnBox = styled.div`
  margin-top: 40px;
  button {
    width: 100%;
    max-width: 240px;
    height: 53px;
    border: 1.5px solid ${(props) => props.theme.color.GPrimary};
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
  }
  @media all and (max-width: 768px) {
    display: flex;
    justify-content: center;
    position: static;
    margin-top: 2.5rem;
    gap: 20px;
    button {
      width: 100%;
      background-size: 7.5rem;
      height: 3rem;
      margin-right: 0.3rem;
    }
  }
`
const AppStoreBtn = styled.button`
  margin-right: 30px;
  background: url('/assets/icons/appstore.png') no-repeat center;
`
const GooglePlayBtn = styled.button`
  background: url('/assets/icons/googleplay.png') no-repeat center;
`

const LinkButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  img {
    width: 30px;
    height: 30px;
  }
  span {
    font-size: 18px;
    color: #00bac7;
    font-weight: 500;
  }
`

export default Product
